import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import "./style.scss"

const PriceBox = props => (
  <div className={`${props.widthClass} price`}>
    <div className={`price-content ${props.featured ? "active" : ""}`}>
      <h3 className={`price-title`}>{props.title}</h3>
      {props.list && (
        <ul className={`list-options`}>
          {props.list.map(item => (
            <li key={item}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <g>
                  <path
                    fill="currentColor"
                    d="M504.5 144.42L264.75 385.5 192 312.59l240.11-241a25.49 25.49 0 0 1 36.06-.14l.14.14L504.5 108a25.86 25.86 0 0 1 0 36.42z"
                  ></path>
                  <path
                    fill="currentColor"
                    d="M264.67 385.59l-54.57 54.87a25.5 25.5 0 0 1-36.06.14l-.14-.14L7.5 273.1a25.84 25.84 0 0 1 0-36.41l36.2-36.41a25.49 25.49 0 0 1 36-.17l.16.17z"
                  ></path>
                </g>
              </svg>
              {item}
            </li>
          ))}
        </ul>
      )}
      <div className={`value`}>
        <span>{props.value}</span>
        {props.period && <span className={`period`}>{props.period}</span>}
      </div>
      <Link to={props.buttonUrl} className={`button orange center`}>
        {props.buttonText}
      </Link>
    </div>
  </div>
)

PriceBox.propTypes = {
  widthClass: PropTypes.string,
  title: PropTypes.string.isRequired,
  list: PropTypes.array,
  value: PropTypes.string.isRequired,
  period: PropTypes.string,
  buttonUrl: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  featured: PropTypes.bool,
}

PriceBox.defaultProps = {
  widthClass: "col-12 col-lg-4",
  title: "",
  value: "",
  period: "por mês",
  buttonUrl: "/register/",
  buttonText: "Quero este",
  featured: false,
}
export default PriceBox
