import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import axios from 'axios';
import Layout from '../components/Layout'
import Seo from '../components/seo'
import Hero from '../components/Hero'
import ExplanationSection from '../components/ExplanationSection'
import SliderDebates from '../components/SliderDebates'
import DebatesBox from '../components/DebatesBox'
import PriceBox from '../components/PriceBox'
import '../scss/page-debates-for-teachers.scss'

class teachersSchoolsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      topDebates: [],
    }
  }

  async componentDidMount() {
    try {
      const top50 = 'http://157.245.122.62:3333/debates?top50=true&order=asc&orderby=date&limit=5';

      const requestOne = await axios.get(top50);

      this.setState(() => ({
        topDebates: requestOne.data.debates,
      }))
    } catch (error) {
      console.log(error.message)
    }
  }

  render() {
    return (
      <Layout>
        <Seo title="Debates for Teachers | Talk of the Town" />

        <Hero text="Debates for Teachers" />
        <ExplanationSection full grayBackground className="introduction">
          <h2 className="text-center">
            NOSSOS DEBATES TOP 50 PARA PROFESSORES PARTICULARES
          </h2>
          <p className="text-center">
            O Talk of the Town selecionou um conteúdo de 50 debates timeless
            - os nossos Top 50 - somente para professores particulares de
            inglês. Cada debate inclui texto sobre um assunto polêmico da
            atualidade, nas versões do aluno e do professor, pesquisa de
            referências contidas no texto, vocabulário, vídeo e roteiro de
            perguntas para conduzir a discussão. Nosso conteúdo pode ser
            adaptado à metodologia e ao estilo de cada profissional e também
            ao perfil e necessidades dos alunos. Os debates estão divididos
            por assunto e nível de dificuldade.
          </p>
          <p className="text-center">
            Você pode escolher e acessar 4 Debates in English diferentes
            para cada mês de assinatura. A cada semana, os temas são
            renovados.
          </p>
          <p className="text-center">
            <strong>Escolha aqui e assine já!</strong>
          </p>
        </ExplanationSection>
        <section className="images-container">
          <Container fluid>
            <Row>
              <Col
                lg="6"
                style={{
                  backgroundImage: `url(${require('../images/imagem_5.jpeg')})`,
                }}
              />
              <Col
                lg="6"
                style={{
                  backgroundImage: `url(${require('../images/imagem_3.jpeg')})`,
                }}
              />
            </Row>
          </Container>
        </section>

        <section className="pricing teachers">
          <div className="container">
            <div className="row containerPrices">
              <PriceBox
                widthClass="col-12 col-lg-4"
                title="Plano Mensal"
                value="R$ 30,00"
                period=""
                buttonUrl="/register/?type=teacher&plan=mensal"
              />
              <PriceBox
                widthClass="col-12 col-lg-4 center"
                title="Plano Trimestral"
                value="R$ 80,00"
                period=""
                buttonUrl="/register/?type=teacher&plan=trimestral"
              />
              <PriceBox
                widthClass="col-12 col-lg-4"
                title="Plano Semestral"
                value="R$ 150,00"
                period=""
                buttonUrl="/register/?type=teacher&plan=semestral"
              />
            </div>
          </div>
        </section>

        <SliderDebates
          sectionTitle="Debates em destaque"
          buttonUrl="/top-50/"
          buttonText="Top 50 debates"
        >
          <div className="row">
            {this.state.topDebates.map((top) => {
              const {
                _id, get_image, date, title, description,
              } = top;
              return (
                <DebatesBox
                  key={_id}
                  widthClass="col-12 col-lg-4 fade-in"
                  background={get_image}
                  dateHour={date}
                  title={title}
                  description={description}
                  buttonUrl={`single/${_id}`}
                  buttonText="Saiba mais"
                  wordLimit={300}
                />
              );
            })}
          </div>
        </SliderDebates>
      </Layout>
    )
  }
}

export default teachersSchoolsPage
